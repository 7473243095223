<template>
    <div>
        <!--begin::Card-->
        <div class="card card-custom card-stretch">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">Personal Information</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
                </div>
                <div class="card-toolbar">
                    <b-button @click="onSubmitPersonal" ref="kt_personal_submit" variant="success" class="mr-2">Save Changes</b-button>
                    <b-button @click="onResetPersonal" ref="kt_personal_reset" variant="outline-primary" class="mr-2">Cancel</b-button>
                </div>
            </div>
            <!--end::Header-->

            <!--begin::Form-->
            <ValidationObserver ref="formPersonal">
            <b-form @submit="onSubmitPersonal" @reset="onResetPersonal" v-if="showPersonal">
                <!--begin::Body-->
                <div class="card-body">
                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">Member Info</h5>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Avatar</label>
                        <div class="col-lg-9 col-xl-6">
                            <div class="image-input image-input-outline" id="kt_profile_avatar" style="background-image: url(media/users/default.png)">
                                <div class="image-input-wrapper" style="background-image: url(media/users/default.png)"></div>

                                <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                    <i class="fa fa-pen icon-sm text-muted"></i>
                                    <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"/>
                                    <input type="hidden" name="profile_avatar_remove"/>
                                </label>

                                <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>

                                <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar">
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                            </div>
                            <span class="form-text text-muted">Allowed file types:  png, jpg, jpeg.</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Full Name</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Full Name" rules="required|min:3" v-slot="{ errors }">
                                <b-form-group
                                    id="input-group-1" 
                                    label-for="input-1"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="(errors.length == 0) ? true : false">
                                    <b-form-input
                                    id="input-1"
                                    autocomplete="off"
                                    v-model="formPersonal.fullName"
                                    class="form-control form-control-solid"
                                    required
                                    size="lg"
                                    placeholder="Full Name"
                                    :state="(errors.length == 0 && formPersonal.fullName != null) ? true : null"
                                    ></b-form-input>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Company Name</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Company Name" rules="required|min:3" v-slot="{ errors }">
                                <b-form-group
                                    id="input-group-2" 
                                    label-for="input-2"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="(errors.length == 0) ? true : false">
                                    <b-form-input
                                    id="input-2"
                                    autocomplete="off"
                                    v-model="formPersonal.companyName"
                                    class="form-control form-control-solid"
                                    required
                                    size="lg"
                                    placeholder="Company Name"
                                    :state="(errors.length == 0 && formPersonal.companyName != null) ? true : null"
                                    ></b-form-input>
                                    <span class="form-text text-muted">If you want to display your company name. Leave it blank to use your full name.</span>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>

                    <div class="separator separator-dashed my-5"></div>

                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">Contact Info</h5>
                        </div>
                    </div>                        
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Contact Phone</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Contact Phone" rules="required" v-slot="{ errors }">
                                <div class="input-group input-group-lg input-group-solid">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="la la-phone"></i></span></div>
                                    <b-form-input
                                        id="input-3"
                                        v-model="formPersonal.phone"
                                        required
                                        autocomplete="off"
                                        class="form-control form-control-solid"
                                        size="lg"
                                        placeholder="Phone"
                                        :state="(errors.length == 0 && formPersonal.phone != null) ? true : null"
                                    >
                                    </b-form-input>
                                </div>
                                <b-form-invalid-feedback :state="(errors.length == 0) ? true : false">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Company Site</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Company Site" :rules="{ regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }" v-slot="{ errors }">
                                <div class="input-group input-group-lg input-group-solid">
                                    <div class="input-group-append"><span class="input-group-text text-muted">https://</span></div>
                                    <b-form-input
                                        id="input-4"
                                        v-model="formPersonal.companySite"
                                        autocomplete="off"
                                        class="form-control form-control-solid pl-0"
                                        size="lg"
                                        placeholder="domain.com"
                                        :state="(errors.length == 0 && formPersonal.companySite != null) ? true : null"
                                    >
                                    </b-form-input>
                                </div>
                                <b-form-invalid-feedback :state="(errors.length == 0) ? true : false">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validationProvider>
                        </div>
                    </div>
                </div>
                <!--end::Body-->
            </b-form>
            </ValidationObserver>
            <!--end::Form-->
        </div>

        <!--begin::Card-->
        <div class="card card-custom mt-8">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
					<h3 class="card-label font-weight-bolder text-dark">Account Information</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account settings</span>
                </div>
                <div class="card-toolbar">
                    <b-button @click="onSubmitAccount" ref="kt_account_submit" variant="success" class="mr-2">Save Changes</b-button>
                    <b-button @click="onResetAccount" ref="kt_account_reset" variant="outline-primary" class="mr-2">Cancel</b-button>
                </div>
            </div>
            <!--end::Header-->

            <!--begin::Form-->
            <ValidationObserver ref="formAccount">
            <b-form @submit="onSubmitAccount" @reset="onResetAccount" v-if="showAccount">
                <div class="card-body">
                    <!--begin::Heading-->
                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">Account:</h5>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Username" rules="required|min:3|username" v-slot="{ errors }">
                                <b-form-group
                                    id="input-group-account-1" 
                                    label-for="input-account-1"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="(errors.length == 0) ? true : false">
                                    <b-form-input
                                    id="input-account-1"
                                    v-model="formAccount.username"
                                    class="form-control form-control-solid"
                                    autocomplete="off"
                                    required
                                    size="lg"
                                    disabled="disabled"
                                    placeholder="Username"
                                    :state="(errors.length == 0 && formAccount.username != null) ? true : null"
                                    ></b-form-input>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Email Address" rules="required|email" v-slot="{ errors }">
                                <div class="input-group input-group-lg input-group-solid">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="la la-at"></i></span></div>
                                    <b-form-input
                                        id="input-account-2"
                                        v-model="formAccount.email"
                                        autocomplete="off"
                                        required
                                        size="lg"
                                        class="form-control form-control-solid"
                                        placeholder="Email"
                                        :state="(errors.length == 0 && formAccount.email != null) ? true : null"
                                    >
                                    </b-form-input>
                                </div>
                                <span class="form-text text-muted">Email will not be publicly displayed. <a href="#" class="kt-link">Learn more</a>.</span>
                                <b-form-invalid-feedback :state="(errors.length == 0) ? true : false">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validationProvider>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">Language</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Language" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    id="input-group-account-3" 
                                    label-for="input-account-3"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="(errors.length == 0) ? true : false">
                                    <b-form-select2
                                    id="input-account-3"
                                    v-model="formAccount.language"
                                    required
                                    variant="solid"
                                    size="lg"
                                    :options="opsLanguage"
                                    placeholder="Username"
                                    :state="(errors.length == 0 && formAccount.language != null) ? true : null"
                                    ></b-form-select2>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label">Communication</label>
                        <div class="col-lg-9 col-xl-6">                            
                            <validationProvider name="Comunication" rules="required" v-slot="{ errors }">
                                <div class="checkbox-inline">
                                    <label class="checkbox">
                                        <input v-model="formAccount.comunication" type="checkbox" value="Email" />
                                        <span></span>
                                        Email
                                    </label>
                                    <label class="checkbox">
                                        <input v-model="formAccount.comunication" type="checkbox" value="SMS" />
                                        <span></span>
                                        SMS
                                    </label>
                                </div>
                                <div class="invalid-feedback d-block">{{ errors[0] }}</div>
                            </validationProvider>
                        </div>
                    </div>
                    
                    <!--begin::Form Group-->
                    <div class="separator separator-dashed my-5"></div>
                    <!--begin::Form Group-->
                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">Security:</h5>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Login verification</label>
                        <div class="col-lg-9 col-xl-6">
                            <button type="button" class="btn btn-light-primary font-weight-bold btn-sm">Setup login verification</button>
                            <p class="form-text text-muted pt-2">
							    After you log in, you will be asked for additional information to confirm your identity and protect your account from being compromised.
							    <a href="#" class="font-weight-bold">Learn more</a>.
							</p>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Password reset verification</label>
                        <div class="col-lg-9 col-xl-6">
                            <div class="checkbox-inline">
                                <label class="checkbox m-0">
                                    <input v-model="formAccount.passwordReset" type="checkbox" />
                                    <span></span>
                                    Require personal information to reset your password.
                                </label>
                            </div>
                            <p class="form-text text-muted py-2">
							     For extra security, this requires you to confirm your email or phone number when you reset your password.
							     <a href="#" class="font-weight-boldk">Learn more</a>.
							</p>
                        </div>
                    </div>
                </div>
            </b-form>
            </ValidationObserver>
            <!--end::Form-->
        </div>
        <!--end::Card-->

         <!--begin::Card-->
        <div class="card card-custom mt-8">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
					<h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span>
                </div>
                <div class="card-toolbar">
                    <b-button @click="onSubmitChangePass" ref="kt_change_pass_submit" variant="success" class="mr-2">Save Changes</b-button>
                    <b-button @click="onResetChangePass" ref="kt_change_pass_reset" variant="outline-primary" class="mr-2">Cancel</b-button>
                </div>
            </div>
            <!--end::Header-->

            <!--begin::Form-->
            <ValidationObserver ref="formChangePass">
            <b-form @submit="onSubmitChangePass" @reset="onResetChangePass" v-if="showChangePass">
                <div class="card-body">
                    <!--begin::Alert-->
                    <b-alert variant="light-danger" show dismissible fade class="alert alert-custom alert-light-danger mb-10">
                        <div class="alert-icon">
                            <span class="svg-icon svg-icon-3x svg-icon-danger">
                                <!--begin::Svg Icon-->
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                                    </g>
                                </svg>
                                <!--end::Svg Icon-->
                            </span>
                        </div>
                        <div class="alert-text font-weight-bold">
                            Configure user passwords to expire periodically. Users will need warning that their passwords are going to expire,<br/>
                            or they might inadvertently get locked out of the system!
                        </div>
                    </b-alert>
                    <!--end::Alert-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-alert">Current Password</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Current password" rules="required" v-slot="{ errors }">
                                <div class="input-group input-group-lg input-group-solid">
                                    <b-form-input
                                        id="input-account-2"
                                        v-model="formChangePass.current"
                                        required
                                        type="password"
                                        size="lg"
                                        class="form-control form-control-solid"
                                        placeholder="Current password"
                                        :state="(errors.length == 0 && formChangePass.current != null) ? true : null"
                                    >
                                    </b-form-input>
                                </div>
                                <a href="#" class="text-sm font-weight-bold">Forgot password ?</a>
                                <b-form-invalid-feedback :state="(errors.length == 0) ? true : false">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validationProvider>
                            
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-alert">New Password</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="New password" rules="required|min:8|password" v-slot="{ errors }">
                                <div class="input-group input-group-lg input-group-solid">
                                    <b-form-input
                                        id="input-account-2"
                                        v-model="formChangePass.newPass"
                                        required
                                        type="password"
                                        size="lg"
                                        class="form-control form-control-solid"
                                        placeholder="New password"
                                        :state="(errors.length == 0 && formChangePass.newPass != null) ? true : null"
                                    >
                                    </b-form-input>
                                </div>
                                <b-form-invalid-feedback :state="(errors.length == 0) ? true : false">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-alert">Verify Password</label>
                        <div class="col-lg-9 col-xl-6">
                            <validationProvider name="Verify password" rules="required|min:8|password|matchPassword:@New password" v-slot="{ errors }">
                                <div class="input-group input-group-lg input-group-solid">
                                    <b-form-input
                                        id="input-account-2"
                                        v-model="formChangePass.verifyPass"
                                        required
                                        type="password"
                                        size="lg"
                                        class="form-control form-control-solid"
                                        placeholder="Verify password"
                                        :state="(errors.length == 0 && formChangePass.verifyPass != null) ? true : null"
                                    >
                                    </b-form-input>
                                </div>
                                <b-form-invalid-feedback :state="(errors.length == 0) ? true : false">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validationProvider>
                        </div>
                    </div>
                </div>
            </b-form>
            </ValidationObserver>
            <!--end::Form-->
        </div>

        <!--begin::Card-->
        <div class="card card-custom mt-8">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
					<h3 class="card-label font-weight-bolder text-dark">Email Settings</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">Change your email settings</span>
                </div>
                <div class="card-toolbar">
                    <b-button @click="onSubmitEmailSetting" ref="kt_email_setting_submit" variant="success" class="mr-2">Save Changes</b-button>
                    <b-button @click="onResetEmailSetting" ref="kt_email_setting_reset" variant="outline-primary" class="mr-2">Cancel</b-button>
                </div>
            </div>
            <!--end::Header-->

            <!--begin::Form-->
            <ValidationObserver ref="formEmailSetting">
            <b-form @submit="onSubmitEmailSetting" @reset="onResetEmailSetting" v-if="showEmailSetting">
                <div class="card-body">
                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">Setup Email Notification:</h5>
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Email Notification</label>
                        <div class="col-lg-9 col-xl-6">
                            <span class="switch switch-sm">
                                <label>
                                    <input type="checkbox" v-model="formEmailSetting.notification"/>
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Send Copy To Personal Email</label>
                        <div class="col-lg-9 col-xl-6">
                            <span class="switch switch-sm">
                                <label>
                                    <input type="checkbox" v-model="formEmailSetting.sendCopy"/>
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>

                    <div class="separator separator-dashed my-10"></div>

                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">Updates From Poultry App:</h5>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left">Email You With</label>
                        <div class="col-lg-9 col-xl-6">
                            <div class="checkbox-list">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="formEmailSetting.news" />
                                    <span></span>
                                     News about national poultry and feature updates
                                </label>
                                <label class="checkbox">
                                    <input type="checkbox" v-model="formEmailSetting.tips" />
                                    <span></span>
                                    Tips for getting more from the Poultry App
                                </label>
                                <label class="checkbox">
                                    <input type="checkbox" v-model="formEmailSetting.log" />
                                    <span></span>
                                    Things you've missed since you last logged into the Poultry App
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </b-form>
            </ValidationObserver>
            <!--end::Form-->
        </div>
    </div>
    
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProfileService from "@/core/services/api/profile.service";
import ErrorService from "@/core/services/error.service";
import { extend } from 'vee-validate';

extend('matchPassword', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('password', {
  validate(value) {
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{1,}$/
    return value.match(validPassword);
  },
  message: 'Password must use a combination of uppercase (A-Z), lowercase (a-z) and numbers (0-9).'
});

extend('username', {
  validate(value) {
    const validUsername = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/
    return value.match(validUsername);
  },
  message: 'Username only letters (a-z), numbers (0-9), underscores (_) and periods (.) Are allowed.'
});

export default {
    name: "profile",
    data() {
        return {
            showPersonal: true,
            showAccount: true,
            showChangePass: true,
            showEmailSetting: true,
            formPersonal: {
                fullName: null,
                companyName: null,
                phone: null,
                companySite: null
            },
            formAccount: {
                username: null,
                email: null,
                language: null,
                comunication: ["Email"],
                LoginVerif: true,
                passwordReset: true
            },
            formChangePass: {
                current: null,
                newPass: null,
                verifyPass: null
            },
            formEmailSetting: {
                notification: true,
                sendCopy: null,
                news: null,
                tips: null,
                log: true
            },
            opsLanguage: [
                {
                    value: null,
                    text: 'Select Language...'
                }, {
                    value: 96,
                    text: 'Bahasa Indonesia'
                }, {
                    value: 229,
                    text: 'English'
                }
            ],
        }
    },
    methods: {
        getPersonal () {
            ProfileService.getPersonal()
                .then((res) => {
                    if (!res.data.status) {
                        ErrorService.message(res.data)
                        return;
                    }
                    this.formPersonal = res.data.data
                    
                    // Trick to reset/clear native browser form validation state
                    this.showPersonal = false
                    this.$nextTick(() => {
                        this.showPersonal = true
                    })
                })
                .catch((err) => ErrorService.status(err))
        },
        getAccount () {
            ProfileService.getAccount()
                .then((res) => {
                    if (!res.data.status) {
                        ErrorService.message(res.data)
                        return;
                    }
                    this.formAccount = res.data.data
                    
                    // Trick to reset/clear native browser form validation state
                    this.showAccount = false
                    this.$nextTick(() => {
                        this.showAccount = true
                    })
                })
                .catch((err) => ErrorService.status(err))
        },
        getEmailSetting () {
            ProfileService.getEmailSetting()
                .then((res) => {
                    if (!res.data.status) {
                        ErrorService.message(res.data)
                        return;
                    }
                    this.formEmailSetting = res.data.data
                    
                    // Trick to reset/clear native browser form validation state
                    this.showEmailSetting = false
                    this.$nextTick(() => {
                        this.showEmailSetting = true
                    })
                })
                .catch((err) => ErrorService.status(err))
        },
        getLanguage () {
            MasterService.getLanguage()
                .then((res) => {
                    if (!res.data.status) {
                        ErrorService.message(res.data)
                        return;
                    }
                    this.opsLanguage = res.data.data
                })
                .catch((err) => ErrorService.status(err))
        },
        removeSpinner(button) { 
            button.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        },
        onSubmitPersonal (evt) {
            evt.preventDefault()

            this.$refs.formPersonal.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }

                // set spinner to submit button
                const submitButton = this.$refs["kt_personal_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                // setTimeout(() => {
                    ProfileService.updatePersonal(this.formPersonal)
                        .then((res) => {
                            this.removeSpinner(submitButton)
                            ErrorService.message(res.data)
                            return;
                        })
                        .catch((err) => {
                            this.removeSpinner(submitButton)
                            ErrorService.status(err)
                        })
                // }, 1000);
            })
        },
        onResetPersonal (evt) {
            evt.preventDefault()
            // set spinner to reset button
            const resetButton = this.$refs["kt_personal_reset"];
            resetButton.classList.add("spinner", "spinner-light", "spinner-right");

            // Reset our form values
            this.formPersonal = {
                fullName: null,
                companyName: null,
                phone: null,
                companySite: null
            }

            // Trick to reset/clear native browser form validation state
            this.showPersonal = false
            this.$nextTick(() => {
                this.showPersonal = true
            })

            // dummy delay
            setTimeout(() => {
                this.removeSpinner(resetButton)
            }, 1000);
        },
        onSubmitAccount (evt) {
            evt.preventDefault()
            this.$refs.formAccount.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }

                // set spinner to submit button
                const submitButton = this.$refs["kt_account_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                // setTimeout(() => {
                    ProfileService.updateAccount(this.formAccount)
                        .then((res) => {
                            this.removeSpinner(submitButton)
                            ErrorService.message(res.data)
                            return;
                        })
                        .catch((err) => {
                            this.removeSpinner(submitButton)
                            ErrorService.status(err)
                        })
                // }, 1000);
            })
        },
        onResetAccount (evt) {
            evt.preventDefault()
            // set spinner to reset button
            const resetButton = this.$refs["kt_account_reset"];
            resetButton.classList.add("spinner", "spinner-light", "spinner-right");

            // Reset our form values
            this.formAccount = {
                username: null,
                email: null,
                language: null,
                timezone: null,
                comunication: ["Email"],
                passwordReset: true

            }

            // Trick to reset/clear native browser form validation state
            this.showAccount = false
            this.$nextTick(() => {
                this.showAccount = true
            })

            // dummy delay
            setTimeout(() => {
                this.removeSpinner(resetButton)
            }, 1000);
        },
        onSubmitChangePass (evt) {
            evt.preventDefault()
            this.$refs.formChangePass.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }

                // set spinner to submit button
                const submitButton = this.$refs["kt_change_pass_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                // setTimeout(() => {
                    ProfileService.updateChangePass(this.formChangePass)
                        .then((res) => {
                            this.removeSpinner(submitButton)
                            ErrorService.message(res.data)
                            return;
                        })
                        .catch((err) => {
                            this.removeSpinner(submitButton)
                            ErrorService.status(err)
                        })
                // }, 1000);
            })
        },
        onResetChangePass (evt) {
            evt.preventDefault()
            // set spinner to reset button
            const resetButton = this.$refs["kt_change_pass_reset"];
            resetButton.classList.add("spinner", "spinner-light", "spinner-right");

            // Reset our form values
            this.formChangePass = {
                current: null,
                newPass: null,
                verifyPass: null
            }

            // Trick to reset/clear native browser form validation state
            this.showChangePass = false
            this.$nextTick(() => {
                this.showChangePass = true
            })

            // dummy delay
            setTimeout(() => {
                this.removeSpinner(resetButton)
            }, 1000);
        },
        onSubmitEmailSetting (evt) {
            evt.preventDefault()
            this.$refs.formEmailSetting.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }
                
                // set spinner to submit button
                const submitButton = this.$refs["kt_email_setting_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                // setTimeout(() => {
                    ProfileService.updateEmailSetting(this.formEmailSetting)
                        .then((res) => {
                            this.removeSpinner(submitButton)
                            ErrorService.message(res.data)
                            return;
                        })
                        .catch((err) => {
                            this.removeSpinner(submitButton)
                            ErrorService.status(err)
                        })

                // }, 1000);
            })
        },
        onResetEmailSetting (evt) {
            evt.preventDefault()
            // set spinner to reset button
            const resetButton = this.$refs["kt_email_setting_reset"];
            resetButton.classList.add("spinner", "spinner-light", "spinner-right");

            // Reset our form values
            this.formEmailSetting = {
                notification: true,
                sendCopy: null,
                news: null,
                tips: null,
                log: true
            }

            // Trick to reset/clear native browser form validation state
            this.showEmailSetting = false
            this.$nextTick(() => {
                this.showEmailSetting = true
            })

            // dummy delay
            setTimeout(() => {
                this.removeSpinner(resetButton)
            }, 1000);
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
        this.getPersonal()
        this.getAccount()
        this.getEmailSetting()
    },
}
</script>